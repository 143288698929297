import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/tailwind.css";
import "vue-js-modal/dist/styles.css";
import shareIt from "vue-share-it";
import VModal from "vue-js-modal";
import VueRouter from "vue-router";
import vueMoment from "vue-moment";
import VueClipboard from "vue-clipboard2";
import VCalendar from "v-calendar";

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core';

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';


/* import specific icons */
import { faUserSecret, faEye } from '@fortawesome/free-solid-svg-icons';

/* add icons to the library */
library.add(faUserSecret);
library.add(faEye);

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.config.productionTip = false;
Vue.use(VueRouter);
Vue.use(shareIt);
Vue.use(VModal);
Vue.use(vueMoment);
Vue.use(VueClipboard);
Vue.use(VCalendar);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
