import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
var CryptoJS = require("crypto-js");

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    auth: null,
  },
  getters: {
    isAuthenticated(state) {
      return !!state.auth || !!sessionStorage.getItem("auth");
    },
    async getAuth(state) {
      if (state.auth === null) {
        state.auth = JSON.parse(sessionStorage.getItem("auth"));
        if (state.auth != null) {
          const getWallet = await axios.post(
            `${process.env.VUE_APP_API_URL}/accountWallet`,
            {
              userSecretKey: state.auth.userSecretKey,
            }
          );

          if (getWallet.data.status_code == 200)
            state.auth.userWalletBalance = getWallet.data.data.ewalletBalance
              ? getWallet.data.data.ewalletBalance
              : 0;

          sessionStorage.setItem("auth", JSON.stringify(state.auth));
        }
      }

      return state.auth;
    },
    getSecretKey(state) {
      return state.auth.userSecretKey;
    },
  },
  mutations: {
    async setAuth(state, token) {
      // console.log("masuk", token);
      token = decodeURIComponent(token);
      var bytes = CryptoJS.AES.decrypt(token, process.env.VUE_APP_HASH_KEY);

      try {
        state.auth = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        // console.log("state.auth", state.auth);

        if (state.auth != null) {
          const getWallet = await axios.post(
            `${process.env.VUE_APP_API_URL}/accountWallet`,
            {
              userSecretKey: state.auth.userSecretKey,
            }
          );

          if (getWallet.data.status_code == 200)
            state.auth.userWalletBalance = getWallet.data.data.ewalletBalance
              ? getWallet.data.data.ewalletBalance
              : 0;
        } else state.auth = null;

        // set in session storage
        sessionStorage.setItem("auth", JSON.stringify(state.auth));
      } catch (error) {
        state.auth = null;
      }
    },
  },
});
export default store;
