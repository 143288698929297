<template>
  <div>
    <div id="header" class="h-20 md:h-30 md:rounded-t-lg" v-if="organization">
      <div class="grid gap-5 grid-cols-2 grid-rows-1 text-white h-full">
        <div class="m-3">
          <div class="grid-cols-1 grid-rows-2 text-left h-2/4">
            {{ organization.organizationName }}
          </div>
          <div class="grid-cols-1 grid-rows-2 text-left h-2/4">
            {{ organization.organizationType }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Top",
  props: ["data"],
  data() {
    return {
      organization: null,
    };
  },
  watch: {
    data: function (value) {
      // console.log("value", value);
      this.organization = value;
    },
  },
};
</script>


