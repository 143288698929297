import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

import store from "../store";

Vue.use(VueRouter)

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue"),
  },
  // {
  //   path: "/baucer",
  //   name: "LandingPage",
  //   component: () => import("../views/Baucer.vue"),
  // },
  // {
  //   path: "/baucer/:baucerCode",
  //   name: "LandingPage",
  //   component: () => import("../views/Baucer.vue"),
  // },
  // {
  //   path: "/institusi/kod-institusi",
  //   name: "LandingPageMock",
  //   component: () => import("../views/mockup/LandingPage.vue"),
  // },
  // {
  //   path: "/institusi/kod-institusi/kod-kempen",
  //   name: "CampaignMock",
  //   component: () => import("../views/mockup/Campaign.vue"),
  // },
  {
    path: "/institusi/:orgcode",
    name: "LandingPage",
    component: () => import("../views/LandingPage.vue"),
  },
  {
    path: "/institusi/:orgcode/basic",
    name: "Payment",
    component: () => import("../views/Payment.vue"),
  },
  {
    path: "/institusi/:orgcode/:campaigncode",
    name: "Campaign",
    component: () => import("../views/Campaign.vue"),
  },
  {
    path: "/receipt",
    name: "Receipt",
    component: () => import("../views/Receipt.vue"),
  },
  // {
  //   path: "/receipt/generate/:transactionid",
  //   name: "ReceiptPDF",
  //   component: () => import("../views/ReceiptPDF.vue"),
  // },

  // UserGroup
  {
    path: '/group',
    name: 'Group List',
    component: () => import('../views/Group/index.vue'),
    meta: { requireAuth: true },
    // children: [
    //   {
    //     path: ':code',
    //     component: () => import('../views/Group/groupInfo.vue'),
    //     meta: { requireAuth: false }
    //   }
    // ]
  },
  {
    path: '/group/:code',
    name: 'Group Detail',
    component: () => import('../views/Group/groupInfo.vue'),
    meta: { requireAuth: true },
  },
  // {
  //   path: '/group/:groupLink',
  //   component: () => import('../views/Group/index.vue')
  // },
  // {
  //   path: '/group',
  //   name: 'GroupUser',
  //   component: () => import('../views/Group/index.vue')
  // },
  {
    path: '/membership/:code',
    name: 'Membership Detail',
    component: () => import('../views/membership/index.vue'),
    meta: { requireAuth: true },
  },


  {
    path: '/login',
    name: 'Auth Login',
    beforeEnter: (to, from, next) => {
      if (store.getters.isAuthenticated && to.query.path) {
        router.push(to.query.path);
      }

      next();
    },
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/logout',
    name: 'Auth Logout',
    beforeEnter: (to, from) => {
      sessionStorage.removeItem('auth');
      router.go(-1);
    },
  },

  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: () => import("../views/Home.vue"),
  },



];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeResolve((to, from, next) => {
  if (!!to.meta.requireAuth && !store.getters.isAuthenticated) {
    console.log(to)
    next('/login?path=' + to.fullPath);
  }

  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    NProgress.start();
  }
  next();
});

router.afterEach((to, from) => {
  // Complete the animation of the route progress bar.
  NProgress.done();
});

export default router;
